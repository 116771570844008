<template>
  <div class="margins1">
    <DayTemplate
      :dateIn="new Date(new Date().setDate(new Date().getDate()))"
      title="Сегодня"
      pathRightSide="/tomorrow"
      pathHidden="/yesterday"
    />
  </div>
</template>

<script>
import DayTemplate from "@/components/DayTemplate.vue";

export default {
  components: { DayTemplate },
  data() {
    return {};
  },
};
</script>
